

export default {
  name: 'SDialogBoxFullscreen',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    buttons: {
      type: Object,
      default: () => ({}),
    },
    noDismiss: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      privateShow: false,
      showDialog: false,
    };
  },
  computed: {
    optionsDialog() {
      return {
        // option persistent = true
        persistent: true,
        // fullscreen en fonction du viewport
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        // passage des options passées par le parent
        maxWidth: this.options.maxWidth,
        scrollable: this.options.scrollable,
        width: this.options.width,
        contentClass: this.options.contentClass,
        retainFocus: this.options.retainFocus,
      };
    },
    buttonsDialog() {
      // bouton closeText par defaut
      if (this.show && !this.buttons.closeText) {
        return { ...this.buttons, closeText: 'Fermer' };
      }
      // passage des boutons passés par le parent
      return this.buttons;
    },
  },
  watch: {
    show() {
      this.privateShow = this.show;
    },
    privateShow() {
      this.$nextTick(() => {
        this.showDialog = this.privateShow;
      });
    },
  },
  created() {
    this.privateShow = this.show;
  },
  methods: {
    dismiss() {
      this.$emit('update:show', false);
      this.$emit('dismiss');
    },
    close() {
      this.$emit('close');
    },
    action() {
      this.$emit('action');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
